@import '~normalize.css/normalize.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
//@import '~@blueprintjs/table/lib/css/table.css';
@import "~@blueprintjs/core/lib/scss/variables";
//@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

//$app-width: 1280px;
$app-width: 98%;
$app-width-min: 1024px;
$app-margin-v: 0px;
$app-margin-h: 8px;
$column-gap: 8px;

body {
  background-color: $pt-app-background-color;
}

.App {
  text-align: center;
  width: $app-width;
  min-width: $app-width-min;
  margin: 0 auto;
}

.AppLogo {
  width: 24px;
  height: 24px;
}

@for $i from 1 through 5 {
  .Spacing-V-#{$i*8} {
    height: $i*8px
  }
}

@for $i from 1 through 5 {
  .Spacing-H-#{$i*8} {
    width: $i*8px
  }
}

.AppBody {
  text-align: left;
  margin: $app-margin-v $app-margin-h;
}

.DialogBody {
    text-align: left;
    margin: $app-margin-v $app-margin-h;
}
.Row-4 {
  display: grid;
  column-gap: $column-gap;
  grid-template-columns: repeat(4, 1fr);
}


@media all and (max-width:1280px) {
  // 8 columns grid
  .Row {
    display: grid;
    column-gap: $column-gap;
    grid-template-columns: repeat(8, 1fr);
  }
  .Col {
    grid-column: 1 / span 8;
  }
  .No-SmallScreen {
    display: none;
  }
}
@media all and (min-width:1280px) {
  // 12 columns grid
  .Row {
    display: grid;
    column-gap: $column-gap;
    grid-template-columns: repeat(12, 1fr);
  }
  .Col {
    grid-column: 1 / span 12;
  }
}
@for $i from 1 through 12 {
  @for $s from 1 through 12-$i+1 {
    .Col-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
  }
}
.Col-H {
  display: flex;
  flex-direction: row;
}
.Col-H > *:not(:first-child) {
  margin-left: $column-gap;
}

.Button-LabeledFormGroup {
  margin-top: 22px;
  margin-bottom: 16px;
}

.Checkbox-LabeledFormGroup {
  margin-top: 28px;
  margin-bottom: 16px;
}

.Checkbox-InlineFormGroup {
  margin-top: 6px;
}

.Select-LabeledFormGroup {
  margin-top: 28px;
  margin-bottom: 16px;
}

@for $i from 0 through 10 {
  .Indent-#{$i} {
    margin-left: $i * 10px;
  }
}

.Indent {
  width: 10px;
}

.Input-XS {
  width: 45px;
}

.TCol-RecNo {
  width: 100px;
}
.TCol-License {
  width: 150px;
}
.TCol-App {
  width: 70px;
}
.TCol-Version {
  width: 70px;
}
.TCol-Edition {
  width: 100px;
}
.TCol-Region {
  width: 70px;
}
.TCol-Channel {
  width: 80px;
}
.TCol-Partner {
  width: 80px;
}
.TCol-Print {
  width: 100px;
}
.TCol-Status {
  width: 100px;
}
.TCol-Email {
  width: 300px;
}
.TCol-Name {
  width: 300px;
}

.TCol-Journal {
  width: 100px;
}
.TCol-Voucher {
  width: 100px;
}
.TCol-HardwareID {
  width: 200px;
}
.TCol-Date {
  width: 100px;
}
.TCol-Time {
  width: 100px;
}
.TCol-Desc {
  width: 300px;
}
.TCol-Reference {
  width: 100px;
}

.Import {
  display: flex;
  flex-direction: column;
}

.TCol-FileName {
  width: 360px;
}
.TCol-FileType {
  width: 160px;
}
.TCol-DateTime {
  width: 200px;
}
.TCol-Records {
  width: 80px;
}
.TCol-Action {
  width: 80px;
}
.TCol-Error {
  width: 150px;
}
.TCol-Ref {
  width: 150px;
}
.TCol-Description {
  width: 400px;
}
.TCol-Message {
  width: 150px;
}
.TCol-Progress {
  width: 400px;
}
.TCol-Log {
  width: 100px;
  overflow: auto;
}

.TCol-EnvironmentName {
  width: 120px;
}
.TCol-CompanyName {
  width: 360px;
}

.TCol-Type {
  width: 100px;
}
.TCol-Group {
  width: 100px;
}
.TCol-Number {
  width: 120px;
}


.MenuIcon {
  width: 16px;
  height: 16px;
}
.MenuTile {
  text-align: center;
  font-weight: bold;
}
.MenuTile:hover {
  transform: scale(1.1);
}
.MenuTileImage {
  width: 100%;
  height: 100%;
}

.MenuEnv {
  min-width: 100px
}

.Align-C {
  text-align: center;
  justify-content: center;
}
.Align-L {
  text-align: left;
}
.Align-R {
  text-align: right;
}

@media print {
  .No-Print {
    display: none !important;
  }

  .Print-Align-C {
    margin: auto;
  }

  .Print-Align-C a {
    color: #182026;
  }

  .Print-Align-C td {
    padding: 3px 11px !important;
  }

  .Print-90-Percent * {
    font-size: 90% !important;
  }
}

@media screen {
  .No-Screen {
    display: none !important;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .App {
    width: unset;
    min-width: unset;
    margin: 0;
  }
  .AppBody {
    margin: 0;
  }
}

.A3667 {
  all: initial;
  padding-top: 14mm;
  padding-right: 8mm;
  padding-bottom: 13mm;
  padding-left: 8mm;
  height: 270mm;
  width: 194mm;

  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(16, 1fr);
  column-gap: 0;
  row-gap: 0;
}

.A3667 > div {
  place-self: center;
}

.Font-XS {
  font-size: 9px;
}