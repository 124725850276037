.Login {
  width: 240px;
  margin: 8% auto;
  display: flex;
  flex-direction: column;
}

.LoginImage {
  width: 240px;
  height: 240px;
}